import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import '../Styles/Services.css';
import axios from 'axios';

class Post extends Component {
  constructor(props){
    super(props);
    this.state={
      post: {
          title: {
              rendered: ''
          },
          content: {
              rendered: ''
          },
          better_featured_image: {
            source_url: ''
          }
      }
    }
  }

  getData(){
    axios.get('https://admin.waterwellconsultants.com/wp-json/wp/v2/posts/' + this.props.match.params.id)
    .then(res => {
      let result = res.data;
      this.setState({
        post: result
    });
    }).catch(err => {
      console.log(err);
    });
}

componentDidMount(){
  this.getData();
}

  render() {
    return (
      <div>
        <Jumbotron style={{backgroundImage: 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.9)), url(' + this.state.post.better_featured_image.source_url + ')', backgroundSize: 'cover', height: '200px'}}>
        </Jumbotron>
        <div id="post">
            <Container>
                <h2 dangerouslySetInnerHTML={{__html: this.state.post.title.rendered}}/>
                <div style={{marginTop: '30px'}}>
                    <div dangerouslySetInnerHTML={{__html: this.state.post.content.rendered}}/>
                </div>
            </Container>
        </div>
      </div>
    )
  }
};

export default Post;