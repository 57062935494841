import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Col, Image, Button } from 'react-bootstrap';
import '../Styles/Services.css';
import loadingGif from '../Images/liquidfill.svg';

class Services extends Component {
  constructor(props){
    super(props);
    this.state={
      loading: true
    }
  }

  componentDidMount(){
    if(this.props.servicesLeft.length > 0 && this.props.servicesRight.length > 0 && this.state.loading === true){
      window.scrollTo(0,0)
      setTimeout(() => {
        this.setState({loading: false});
      }, 300)
    }
  }

  componentDidUpdate(){
    if(this.props.servicesLeft.length > 0 && this.props.servicesRight.length > 0 && this.state.loading === true){
      setTimeout(() => {
        this.setState({loading: false});
      }, 300)
    }
  }

  render() {
    let loading = <Row><img id='loadingGif' src={loadingGif} alt="loading"/></Row>
    let body = <Row style={{marginTop: '50px'}}>
    <Col md={6} xs={12}>
    {
      this.props.servicesLeft.map((item,index)=> (
        <div dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
      ))
    }
    </Col>
    <Col md={6} xs={12}>
    {
      this.props.servicesRight.map((item,index)=> (
        <div dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
      ))
    }
    </Col>
  </Row>
    let content;
    if(this.state.loading === true){
      content = loading;
    }else{
      content = body;
    }

    return (
      <div>
        <Jumbotron id="servicesJumbo">
            <h1>Services Offered</h1>
        </Jumbotron>
        <div id="servicesInfo">
            <Container>
            {content}
            <Row>
              <Link style={{margin: 'auto', marginTop: '25px'}} to="/contact">  
                <Button variant="outline-primary" size="lg">Contact Us</Button>
              </Link>
            </Row>
            <Row>
              {/* <Image class="containerImg" src="http://www.waterwellconsultants.com/images/services.jpg" fluid style={{margin: 'auto', marginTop: '10px'}}/> */}
            </Row>
            </Container>
        </div>
      </div>
    )
  }
};

export default Services;