import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Jumbotron, Container, Row, Button } from 'react-bootstrap';
import '../Styles/Home.css';
import PostCard from './Card.js';
import axios from 'axios';
import loadingGif from '../Images/liquidfill.svg';

class Home extends Component {
  constructor(props){
    super(props);
    this.state={
      Posts: [],
      loading: true
    }
  }

getData(){
    axios.get('https://admin.waterwellconsultants.com/wp-json/wp/v2/posts').then(res => {
      let result = res.data;
      result.forEach((item) => {
        item.title.rendered = item.title.rendered.replace('#038;', '');
        item.excerpt.rendered = item.excerpt.rendered.replace('<p>', '');
        item.excerpt.rendered = item.excerpt.rendered.replace('amp;', '');
        item.excerpt.rendered = item.excerpt.rendered.replace('</p>', '');
      });
      setTimeout(
        function() {
            this.setState({
              loading: false,
              Posts: result
          });
        }
        .bind(this),
        100
      );
    }).catch(err => {
      console.log(err);
    });
}

componentDidMount(){
  this.getData();
}

  render() {
    return (
      <div>
        <Jumbotron>
        <h2>Groundwater Specialists</h2>
        <p>Looking for a hydrologist, hydrogeologist, or geologist around Idaho Falls, Idaho?</p>
        <Link to="/services">
          <Button size="lg" variant="primary">Learn More</Button>
        </Link>
        </Jumbotron>
        <Container>
          { this.state.loading && <img id='loadingGif' src={loadingGif} alt="loading"/> }
          <Row>
            {
              this.state.Posts.map((post,index)=> (
                <PostCard key={index} img={post.better_featured_image.source_url} title={post.title.rendered} body={post.excerpt.rendered} refid={post.id}/>
              ))
            }
          </Row>
        </Container>
      </div>
    )
  }
};

export default Home;