import React, { Component } from 'react'
import { Jumbotron, Container, Row, Col, Button, Form, Image } from 'react-bootstrap';
import '../Styles/Contact.css';
import loadingGif from '../Images/liquidfill.svg';
import axios from 'axios';

class Contact extends Component {
  constructor(props){
    super(props);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.state={
      formInfo: {
        Name: '',
        Email: '',
        PhoneNumber: '',
        Subject: '',
        Message: '',
      },
      loading: true
    }
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  handleFormSubmission(e) {
    e.preventDefault();
    axios.post('https://api.emailjs.com/api/v1.0/email/send',{
      service_id: 'gmail',
      template_id: 'template_Pui7jPIZ',
      user_id: 'user_5xQBJmS9dfOsippbV1NiI',
      template_params: {
          'reply_to': this.state.Name,
          'from_name': this.state.Name,
          'message_html': this.state.Message,
          'email_address': this.state.Email,
          'phone_number': this.state.PhoneNumber,
          'subject': this.state.Subject,
      }
    } ).then(res=> {
      alert('Your message was sent!');
  }).catch(err => {
      alert('Sorry an error occured, please try sending the message again');
  })

document.getElementById('contactForm').reset();
}

  componentDidMount(){
    if(this.props.contactInfo.length > 0 && this.state.loading === true){
      window.scrollTo(0,0)
      setTimeout(() => {
        this.setState({loading: false});
      }, 300)
    }
  }

  componentDidUpdate(){
    if(this.props.contactInfo.length > 0 && this.state.loading === true){
      setTimeout(() => {
        this.setState({loading: false});
      }, 300)
    }
  }

  render() {
    let loading =<Row><img id='loadingGif' src={loadingGif} alt="loading"/></Row>;
    let body = <Row>
    <Col id='contactAddr'>
        <b>Address</b>
        <p>
          Water Well Consultants, Inc.
        <br/>
          355 West 500 South
          Burley, Idaho
          83318
        </p>
        <b>Phone</b>
        <p>
          208-650-6605
        </p>
        <b>Fax</b>
        <p>
          1-866-604-4640
        </p>
        <b>Mail</b>
        <p>
          mail@waterwellconsultants.com
        </p>
    </Col> 
    <Col>
    {
      this.props.contactInfo.map((item,index) => (
        <div key={index} dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
      ))
    }
    </Col>
    </Row>

    let content;
    if(this.state.loading === true){
      content = loading;
    }else{
      content = body;
    }

    return (
      <div>
        <div id="contactInfo">
        <Jumbotron id="contactJumbo">
            <h1>Contact Us</h1> 
        </Jumbotron>
          <Container>
              {content}
              <Row>
                {/* <Image src="http://www.waterwellconsultants.com/images/trucks.jpg" fluid style={{margin: "auto", marginTop: "25px"}}/> */}
              </Row>
              <Row style={{marginTop: '40px', borderTop: '1px gray solid'}}>
              <Form id='contactForm' style={{marginTop: '30px'}}>
                <Form.Group>
                  <Form.Control placeholder="Name" type='text' name='Name' onChange={this.handleChange} />
                </Form.Group>

                <Form.Group>
                  <Form.Control placeholder='Email' type='text' name='Email' onChange={this.handleChange} />
                </Form.Group>

                <Form.Group>
                  <Form.Control placeholder='Phone' type='text' name='PhoneNumber' onChange={this.handleChange} />
                </Form.Group>

                <Form.Group>
                  <Form.Control placeholder='Subject' type='text' name='Subject' onChange={this.handleChange} />
                </Form.Group>

                <Form.Group>
                  <Form.Control placeholder='Message' type='textarea' name='Message' onChange={this.handleChange} />
                </Form.Group>

                <Button variant="primary" onClick={this.handleFormSubmission}>Send Message</Button>
              </Form>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
};

export default Contact;