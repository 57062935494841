import React, { Component } from 'react';
import Navbr from './Components/Navbar.js';
import Home from './Components/Home.js';
import Footer from './Components/Footer.js';
import Contact from './Components/Contact.js';
import Services from './Components/Services.js';
import Post from './Components/Post.js';
import GenericPage from './Components/GenericPage.js';
import {BrowserRouter, Route, Link} from 'react-router-dom';
import logo from './logo.svg';
import './Styles/App.css';
import axios from 'axios';

const NotFoundPage = () => (
  <div>
      404 - <Link to="/">Go to Home</Link>
  </div>
);


class App extends Component {
  constructor(props){
    super(props);
    this.state={
      servicesLeft: [],
      servicesRight: [],
      contactInfo: []
    }
  }

getData(){
  axios.get('https://admin.waterwellconsultants.com/wp-json/wp/v2/pages/?slug=servicesleft')
  .then(res => {
    let result = res.data;
    console.log(result);
    this.setState({
      servicesLeft: result
  });
  }).catch(err => {
    console.log(err);
  });

  axios.get('https://admin.waterwellconsultants.com/wp-json/wp/v2/pages/?slug=servicesright')
  .then(res => {
    let result = res.data;
    console.log(result);
    this.setState({
      servicesRight: result
  });
  }).catch(err => {
    console.log(err);
  });

  axios.get('https://admin.waterwellconsultants.com/wp-json/wp/v2/pages/?slug=contact')
  .then(res => {
    let result = res.data;
    console.log(result);
    this.setState({
      contactInfo: result
    });
    }).catch(err => {
      console.log(err);
    });
}

componentDidMount(){
  this.getData();
}
  
render() {
  return (
    <div className="App">
      <Navbr/>
      <BrowserRouter>
        <Route exact path="/" component={Home} />
        <Route exact path="/Contact" render={()=><Contact contactInfo={this.state.contactInfo}/>} />
        <Route exact path="/Services" render={()=><Services servicesLeft={this.state.servicesLeft} servicesRight={this.state.servicesRight}/>}/>
        <Route path='/Page/:slug' component={GenericPage} />
        <Route path='/Post/:id' component={Post} />
      </BrowserRouter>
      <Footer/>
    </div>
  );
}
};

export default App;
