import React, { Component } from 'react'
import { Jumbotron, Container } from 'react-bootstrap';
import axios from 'axios';
import '../Styles/GenericPage.css';

class GenericPost extends Component {
  constructor(props){
    super(props);
    this.state={
      post: {
          title: {
              rendered: ''
          },
          content: {
              rendered: ''
          },
          better_featured_image: {
            source_url: ''
          }
      }
    }
  }

  getData(){
    axios.get('https://admin.waterwellconsultants.com/wp-json/wp/v2/pages/?slug=' + this.props.match.params.slug)
    .then(res => {
      let result = res.data;
      this.setState({
        post: result[0]
    });
    }).catch(err => {
      console.error(err);
    });
}

componentDidMount(){
  this.getData();
}

  render() {
    return (
      <div>
        <div>
            <Jumbotron id="genericJumbo">
            </Jumbotron>
            <Container>
                <h2 dangerouslySetInnerHTML={{__html: this.state.post.title.rendered}}/>
                <div id='genericContent'>
                    <div dangerouslySetInnerHTML={{__html: this.state.post.content.rendered}}/>
                </div>
            </Container>
        </div>
      </div>
    )
  }
};

export default GenericPost;