import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import img from '../Images/waterlogo.jpg';
import '../Styles/Navbar.css';

class Navbr extends Component {
    render() {
      return (
        <Navbar bg="light" expand="lg" sticky="top">
            <Navbar.Brand href="/">
            <img
                src={img}
                width="105"
                height="65"
                className="d-inline-block align-top"
                alt="Waterwell Consultants"
            />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/Services">Services</Nav.Link>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                    {/* <Nav.Link href="https://wwc.pow.dev/login">Database</Nav.Link> */}
                    <NavDropdown title="Districts" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/Page/AmericanFallsAberdeen">American Falls/Aberdeen</NavDropdown.Item>
                        <NavDropdown.Item href="/Page/bonneville-jefferson">Bonneville/Jefferson</NavDropdown.Item>
                        <NavDropdown.Item href="/Page/goose-creek">Goose Creek</NavDropdown.Item>
                        <NavDropdown.Item href="/Page/magic-valley">Magic Valley</NavDropdown.Item>
                        <NavDropdown.Item href="/Page/north-snake">North Snake</NavDropdown.Item>
                        <NavDropdown.Item href="/Page/raft-river">Raft River</NavDropdown.Item>
                        <NavDropdown.Item href="/Page/southwest">Southwest</NavDropdown.Item>
                        <NavDropdown.Item href="/Page/wd140">WD140</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Links" id="basic-nav-dropdown">
                        <NavDropdown.Item href="http://www.idwr.idaho.gov">IDWR</NavDropdown.Item>
                        <NavDropdown.Item href="http://www.idwr.idaho.gov/WaterManagement/WaterRights/default.htm">Idaho Water Rights</NavDropdown.Item>
                        <NavDropdown.Item href="http://www.usgs.gov">USGS</NavDropdown.Item>
                        <NavDropdown.Item href="http://www.noaa.gov">NOAA</NavDropdown.Item>
                        <NavDropdown.Item href="http://nws.noaa.gov">NWS</NavDropdown.Item>
                        <NavDropdown.Item href="http://www.usbr.gov/pn/hydromet/burtea.cfm">Pacific Northwest DOI</NavDropdown.Item>
                        <NavDropdown.Item href="https://wwc.pow.dev/login">Database</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="About Us" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/Page/our-story">Our Story</NavDropdown.Item>
                        <NavDropdown.Item href="/Page/philanthropy">Charitable Projects</NavDropdown.Item>
                        <NavDropdown.Item href="/Page/references">Reference</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
      );
    }
    };
    
    export default Navbr;