import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Card } from 'react-bootstrap';
import '../Styles/Contact.css';

class PostCard extends React.Component {

  constructor(props){
    super(props);
    this.state={
      url: '/Post/' + props.refid
    }
  }

  render(){
    return(
    <Col xs={12} sm={12} md={4} className="person-wrapper">
        <Link to={this.state.url}>
          <Card>
              <Card.Img className="cardimg" variant="top" src={this.props.img} />
              <Card.Body>
              <Card.Title style={{fontWeight: 600}}>{this.props.title}</Card.Title>
              <Card.Text>
                  {this.props.body}
              </Card.Text>
              </Card.Body>
          </Card>
        </Link>
    </Col>
    )
  }
}

export default PostCard;