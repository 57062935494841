import React from 'react';
import '../Styles/Footer.css';

class Footer extends React.Component {
	render() {
	  return (
		<footer className="footer-distributed">
			<div className="footer-right">
				<a href='https://www.facebook.com/pages/Water-Well-Consultants/158872077477935'><i className="fa fa-facebook"></i></a>
				{' '}
				<a href='https://www.linkedin.com/company/water-well-consultants/about/'><i className="fa fa-linkedin"></i></a>
			</div>
			<div className="footer-left">
				<p>Copyright &copy; Water Well Consultants 2019. All rights reserved. Site built by <a style={{color: 'white'}} href="https://www.linkedin.com/in/taylorbakow">Taylor Bakow</a> with<img class='App-logo' src='https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/800px-React-icon.svg.png'/>React.js</p>
			</div>
		</footer>
		);
	}
}

export default Footer;